import React from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    CardTitle,
  } from "reactstrap";

class CardSite extends React.Component {
    constructor(props) {
        super(props);
    }

    editSite(site_id) {
        alert("Editing site #: " + site_id);
    }

    render() {
        return (
            <>
                <Col lg="12">
                    <Card className="siteCard">
                        <CardBody>
                            <CardTitle>
                                <h4>{this.props.site_name}</h4>
                                <div className="actionButtons float-end">
                                    <Button onClick={this.editSite.bind(this, this.props.site_id)} className="btn-outline-default" type="button"><i className="fas fa-edit" /> Edit Site</Button>
                                </div>
                            </CardTitle>
                            <hr />
                            <p>GUID: {this.props.site_data.plesk_guid}</p>

                        </CardBody>
                    </Card>
                </Col>
            </>
        );
    }
}
export default CardSite;