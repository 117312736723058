import React from "react";

// reactstrap components
import {
  Container,
  Row,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import CardSite from "components/Sites/Site";


class MainDashboard extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      error: null,
      isLoaded: false,
      sites: []
    }

  }

  componentDidMount() {
    console.log(this.props);

    var formData = new FormData();
    formData.append('fname', 'getSitesByUserId');
    formData.append('user_id', 71); //change this value to demo <--------
    fetch("http://local.api.sso.rocketsites.com", {
      method: 'POST',
      body: formData
      
    })
      .then(res => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          isLoaded: true,
          sites: result
        });
      })

    
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container fluid>
          <Row>
            {this.state.sites.map((site, i) => {
              console.log(site);              

              return <CardSite key={site.id} site_id={site.id} site_name={site.domain} site_data={site} />

            })}
          </Row>
        </Container>
      </>
    );
  }
};

export default MainDashboard;
